<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col-md-12">
        <b-card class="gutter-b">
          <div class="row">
            <div class="col-md-4">
              <b-card class="text-primary border-primary">
                <h5>Tổng Hoa Hồng</h5>
                <h1>{{ formatPrice(commission.total || 0, "") }}</h1>
              </b-card>
            </div>
            <div class="col-md-4">
              <b-card class="text-success border-success">
                <h5>Hoa Hồng Đã Nhận</h5>
                <h1>{{ formatPrice(commission.received || 0, "") }}</h1>
              </b-card>
            </div>
            <div class="col-md-4">
              <b-card class="text-warning border-warning">
                <h5>Hoa Hồng Còn Lại</h5>
                <h1>{{ formatPrice(commission.remain || 0, "") }}</h1>
              </b-card>
            </div>
          </div>
        </b-card>
      </div>
      <div class="col-md-12">
        <b-card class="gutter-b">
          <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-2"></div>
            <div class="col-md-2"></div>
            <div class="col-md-2">
              <b-form-group
                v-if="filterDate < 0"
                id="input-from-date"
                label="Từ Ngày"
                label-for="from-date"
              >
                <datePicker
                  v-model="from"
                  :config="dpOptions"
                  @dp-change="changeFilterDate(-1)"
                ></datePicker>
              </b-form-group>
            </div>
            <div class="col-md-2">
              <b-form-group
                v-if="filterDate < 0"
                id="input-to-date"
                label="Đến Ngày"
                label-for="to-date"
              >
                <datePicker
                  v-model="to"
                  :config="dpOptions"
                  @dp-change="changeFilterDate(-1)"
                ></datePicker>
              </b-form-group>
            </div>
            <div class="col-md-2">
              <b-dropdown
                block
                :text="filterDateText()"
                variant="outline-info"
                class="text-info mb-3"
                :class="{ 'search-btn': filterDate < 0 }"
                menu-class="w-100"
              >
                <b-dropdown-item
                  v-for="date in filterDateList"
                  :active="activeFilteredDate(date)"
                  :key="'filter_date_' + date.value"
                  @click="changeFilterDate(date.value)"
                  >{{ date.text }}</b-dropdown-item
                >
              </b-dropdown>
            </div>
            <div class="col-md-4">
              <b-card class="text-primary border-primary">
                <h5>Khách Hàng Đăng Ký</h5>
                <h1>{{ formatPrice(total.user || 0, "") }}</h1>
                <router-link to="/customers" class="text-primary"
                  >Chi tiết</router-link
                >
              </b-card>
            </div>
            <div class="col-md-4">
              <b-card class="text-success border-success">
                <h5>Đơn Hàng</h5>
                <h1>{{ formatPrice(total.ticket || 0, "") }}</h1>
                <router-link to="/tickets" class="text-success"
                  >Chi tiết</router-link
                >
              </b-card>
            </div>
            <div class="col-md-4">
              <b-card class="text-danger border-danger">
                <h5>Hoa Hồng</h5>
                <h1>{{ formatPrice(total.commission || 0, "") }}</h1>
                <router-link to="/commissions" class="text-danger"
                  >Chi tiết</router-link
                >
              </b-card>
            </div>
          </div>
        </b-card>
      </div>
      <!-- <div class="col-md-6">
        <MixedWidget1></MixedWidget1>
      </div> -->
      <!-- <div class="col-md-4">
        <ListWidget9></ListWidget9>
      </div> -->
      <!-- <div class="col-md-6">
        <StatsWidget7></StatsWidget7>
        <StatsWidget12></StatsWidget12>
      </div> -->

      <!-- <div class="col-md-6 order-1 order-xxl-1">
        <ListWidget1></ListWidget1>
        <ListWidget1></ListWidget1>
      </div>
      <div class="col-md-6 order-1 order-xxl-1">
        <ListWidget3></ListWidget3>
      </div>

      <div class="col-md-12 order-2 order-xxl-1">
        <AdvancedTableWidget2></AdvancedTableWidget2>
      </div> -->
    </div>
    <!--end::Dashboard-->
  </div>
</template>
<style lang="scss">
.dropdown-menu {
  .active,
  .dropdown-item:active {
    background-color: #8950fc;
  }
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
// import AdvancedTableWidget2 from "@/view/content/widgets/advance-table/Widget2.vue";
// import MixedWidget1 from "@/view/content/widgets/mixed/Widget1.vue";
// import ListWidget1 from "@/view/content/widgets/list/Widget1.vue";
// import ListWidget3 from "@/view/content/widgets/list/Widget3.vue";
// import ListWidget4 from "@/view/content/widgets/list/Widget4.vue";
// import ListWidget8 from "@/view/content/widgets/list/Widget8.vue";
// import ListWidget9 from "@/view/content/widgets/list/Widget9.vue";
// import StatsWidget7 from "@/view/content/widgets/stats/Widget7.vue";
// import StatsWidget12 from "@/view/content/widgets/stats/Widget12.vue";
// import ApiService from "@/core/services/api.service.js";
import ApiService from "@/core/services/api.service.js";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import datePicker from "vue-bootstrap-datetimepicker";

export default {
  name: "dashboard",
  components: {
    // AdvancedTableWidget2,
    // MixedWidget1,
    // ListWidget1,
    // ListWidget3,
    // ListWidget4,
    // ListWidget8,
    // ListWidget9,
    // StatsWidget7,
    // StatsWidget12,
    datePicker
  },
  data() {
    return {
      commission: {},
      total: {},
      filterDateList: [
        { value: 0, text: "Hôm nay" },
        { value: 1, text: "Hôm qua" },
        { value: 7, text: "7 ngày trước" },
        { value: 14, text: "14 ngày trước" },
        { value: 30, text: "30 ngày trước" },
        { value: 90, text: "90 ngày trước" },
        { value: 180, text: "180 ngày trước" },
        { value: -1, text: "Tuỳ chỉnh" }
      ],
      filterDate: 7,
      from: "",
      to: "",
      dpOptions: {
        format: "MM/DD/YYYY",
        sideBySide: true
      }
    };
  },
  created() {
    this.changeFilterDate(this.filterDate);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Trang chủ" }]);
  },
  computed: {
    campaignId() {
      return this.$store.state.auth.campaignId;
    }
  },
  watch: {
    campaignId() {
      this.changeFilterDate(this.filterDate);
    }
  },
  methods: {
    activeFilteredDate(date) {
      return this.filterDate == date.value;
    },
    filterDateText() {
      return this.filterDateList.find(date => date.value == this.filterDate)
        .text;
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
    changeFilterDate(value) {
      this.filterDate = value;
      let from = new Date(this.from);
      let to = new Date(this.to);
      if (value >= 0) {
        from = new Date();
        to = new Date();
        from.setDate(from.getDate() - value);
      }
      if (value > 0) {
        to.setDate(to.getDate() - 1);
      }
      from.setHours(0, 0, 0, 0);
      to.setHours(23, 59, 59);
      this.from = new Date(from);
      this.to = new Date(to);
      this.getSummary();
    },
    getSummary() {
      ApiService.query("/partners/summaries", {
        from: this.from,
        to: this.to
      }).then(response => {
        this.commission = response.data.data.commission || {};
        this.total = response.data.data.total || {};
      });
    }
  }
};
</script>
